import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import gigya from "../lib/gigya";
import { AUTHENTICATION_STATUS, ROUTES_PATH } from "../constants";
import { API_URLS } from "../constants/apiUrls";

import GTMACTIONS from "../config/gtmActions";
import { datalayerPush } from "../lib/gtm";
import { getCurrentLocale } from "../i18n";

export type User = {
  errorCode?: number;
  errorMessage?: string;
  UID: string;
  UIDSignature: string;
  signatureTimestamp: string;
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    photoURL: string;
  };
};

export interface Auth {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  status?: string;
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
  error?: string | null;
  setError?: React.Dispatch<React.SetStateAction<null>>;
  checkSessionStatus?: () => void;
  generateAuthToken?: (response: User) => void;
  logout?: () => void;
  afterLogout?: () => void;
  isTokenGenerating?: boolean;
  inAppAuth?: boolean;
  locale?: string;
  fetchMyProfileData?: () => void;
}

const authContext = createContext({});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState<User>({} as User);
  const [status, setStatus] = useState(AUTHENTICATION_STATUS.LOADING);
  const [error, setError] = useState(null);
  const [isTokenGenerating, setIsTokenGenerating] = useState(false);
  const inAppAuth = process.env.REACT_APP_IN_APP_AUTH?.toLowerCase() === "true";

  /* Use this function to check user session status.
    If session doesnt exist, set status to "Not-Authenticated"
    Return Session Status type: Boolean
  */
  function checkSessionStatus() {
    gigya.hasSession().then((sessionExist: boolean) => {
      if (!sessionExist) {
        setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
        inAppAuth && navigate("/", { replace: true });
      }
      return sessionExist;
    });
  }

  /**
   * function to generate token after login or when there is session
   * @param response User
   */
  function generateAuthToken(response: User) {
    axios
      .get(`${process.env.REACT_APP_SESSION_API_URL}/v1/auth/token`, {
        params: {
          UID: response.UID,
          UIDSignature: response.UIDSignature,
          signatureTimestamp: response.signatureTimestamp,
          brand: "HILLS",
        },
      })
      .then((apiResponse: AxiosResponse) => {
        if (apiResponse.status === 200) {
          //adding succesful login gtm event
          datalayerPush(GTMACTIONS.LOGIN_SUCCESS);
          window?.localStorage.setItem("gigyaToken", apiResponse.data.token);
          setUser({
            UID: response.UID,
            UIDSignature: response.UIDSignature,
            signatureTimestamp: response.signatureTimestamp,
            profile: {
              firstName: response.profile.firstName,
              lastName: response.profile.lastName,
              email: response.profile.email,
              photoURL: response.profile.photoURL,
            },
          });
          setStatus(AUTHENTICATION_STATUS.AUTHENTICATED);
          setIsTokenGenerating(false);
          navigate(ROUTES_PATH.ACCOUNT_HOME, { replace: true });
        } else {
          setIsTokenGenerating(false);
          setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
          !inAppAuth && navigate(ROUTES_PATH.ERROR_PAGE, { replace: true });
        }
      })
      .catch((err) => {
        const errorContainer = document.getElementById(
          "signin-error"
        ) as HTMLElement;
        errorContainer.innerHTML = err.message
          ? err.message
          : `${t("common.updateFailed")}`;
      });
  }

  function logout() {
    gigya.accounts.logout();
  }

  function afterLogout() {
    if (inAppAuth) {
      axios
        .delete(`${process.env.REACT_APP_SESSION_API_URL}/v1/auth/token`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("gigyaToken")}`,
          },
        })
        .finally(() => {
          window.localStorage.removeItem("gigyaToken");
          setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
          navigate("/", { replace: true });
        });
    }
  }

  function fetchMyProfileData() {
    axios
      .get(`${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_PROFILE}`)
      .then((apiResponse) => {
        if (apiResponse?.data?.serviceStatus?.code === 200) {
          setUser({
            ...user,
            profile: apiResponse.data?.profile,
          });
        }
      });
  }

  useEffect(() => {
    /* 
      Fetch the user's information.
      If fetch is successful, set the user's information to the auth state.
      If fetch is unsuccessful, set the error to the status state.
    */
    function fetchUserData(response: User) {
      if (response.errorCode === 0) {
        if (localStorage.getItem("gigyaToken") !== null) {
          setUser({
            UID: response.UID,
            UIDSignature: response.UIDSignature,
            signatureTimestamp: response.signatureTimestamp,
            profile: {
              firstName: response.profile.firstName,
              lastName: response.profile.lastName,
              email: response.profile.email,
              photoURL: response.profile.photoURL,
            },
          });
          setStatus(AUTHENTICATION_STATUS.AUTHENTICATED);
        } else {
          setIsTokenGenerating(true);
          generateAuthToken(response);
        }
      } else {
        setError(response.errorMessage as unknown as SetStateAction<null>);
        !inAppAuth && navigate(ROUTES_PATH.ERROR_PAGE, { replace: true });
      }
    }

    /* 
      Check if the user is logged in.
      If the user is logged in, fetch user information and set to the global state.
      If the user is not logged in, set the global state to an empty object.
    */
    try {
      gigya.hasSession().then((sessionExist: boolean) => {
        if (sessionExist) {
          gigya.accounts.getAccountInfo({ callback: fetchUserData });
        } else {
          setUser({} as User);
          setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
          !inAppAuth && navigate(ROUTES_PATH.ERROR_PAGE, { replace: true });
        }
      });
    } catch {
      setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
      !inAppAuth && navigate(ROUTES_PATH.ERROR_PAGE, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      fetchMyProfileData();
    }
  }, [status]);

  return {
    user,
    setUser,
    status,
    setStatus,
    error,
    setError,
    checkSessionStatus,
    generateAuthToken,
    logout,
    afterLogout,
    isTokenGenerating,
    inAppAuth,
    locale: getCurrentLocale(),
    fetchMyProfileData,
  };
}
