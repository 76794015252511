import { useTranslation } from "react-i18next";
import { PetNameDefaultImage } from "ui-2";
import { formatBirthday } from "utils";
import { PetType } from "../../../types";
import { birthdayIcon, genderIcon, weightIcon, editIcon2 } from "../../icons";
import { MY_PETS, ROUTES_PATH } from "../../constants";
import { BreedType } from "../../../types";
import useSearchPersistantNavigate from "../../hooks/useSearchPersistantNavigate";
import { useMyPets } from "../../hooks/useMyPet";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm";

const BasicInfoCard = ({
  petData,
}: {
  petData: PetType & { breedData?: BreedType };
}) => {
  const { t } = useTranslation();
  const searchPersistantNavigate = useSearchPersistantNavigate();
  const { isViewRememberedPets } = useMyPets();

  const onEdit = () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_PROFILE_EDIT_A_PET);
    searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`, {
      state: petData,
    });
  };

  let petSize = undefined;
  switch (petData.petTypeId) {
    case 1:
      switch (petData.petSizeId) {
        case 1:
          petSize = MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_DOG;
          break;
        case 2:
          petSize = MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_DOG;
          break;
        case 3:
          petSize = MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_DOG;
          break;

        default:
          break;
      }
      break;
    case 2:
      switch (petData.petSizeId) {
        case 1:
          petSize = MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_CAT;
          break;
        case 2:
          petSize = MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_CAT;
          break;
        case 3:
          petSize = MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_CAT;
          break;

        default:
          break;
      }
      break;
    default:
      break;
  }

  const cardData = {
    petSize: petSize && String(t(petSize)),
    petGender:
      petData.petGenderId == 1
        ? String(t(MY_PETS.ADD_EDIT_PET.MALE))
        : petData.petGenderId == 2
        ? String(t(MY_PETS.ADD_EDIT_PET.FEMALE))
        : undefined,
  };

  return (
    <div
      aria-label="basic-info-card"
      data-testid="basic-info-card"
      className="flex w-full flex-col gap-2"
    >
      <div className=" flex flex-row items-center justify-between self-stretch">
        <div className="label2 lg:headline5">{t(MY_PETS.PROFILE.LABEL1)}</div>
        {!isViewRememberedPets && (
          <button
            data-testid="edit-button"
            aria-label="edit button"
            onClick={() => onEdit()}
            className={`label2 lg:label1 flex cursor-pointer items-center justify-center gap-1 text-[#0054A4]`}
          >
            <div className="h-[18px] w-[18px] fill-[#0054A4] lg:h-[22px] lg:w-[22px]">
              {editIcon2}
            </div>
            <div>{t(MY_PETS.PROFILE.EDIT)}</div>
          </button>
        )}
      </div>
      <div className="flex w-full flex-col gap-3 rounded-lg p-4 shadow-dp2 lg:gap-4 lg:p-6">
        <div className="flex gap-4">
          <div className="h-12 w-12 lg:h-14 lg:w-14">
            {petData.petImage ? (
              <img
                aria-label="pet-image"
                src={petData.petImage}
                alt={petData.petName}
                className="h-12 w-12 rounded-full object-cover lg:h-14 lg:w-14"
              />
            ) : (
              PetNameDefaultImage(petData.petName)
            )}
          </div>
          <div className="flex flex-col gap-1 lg:gap-2">
            <div className="headline5 lg:headline4">{petData.petName}</div>
            <div className="body3 lg:body2 text-[#66676B]">
              {petData?.breedData
                ? t(`petBreeds.${petData?.breedData?.petBreedName}`)
                : t(MY_PETS.PROFILE.NO_BREED)}
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between self-stretch text-[#66676B]">
          <div className="body3 lg:body2 flex w-[64px] flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              {birthdayIcon}
            </div>
            <div>
              {formatBirthday(
                petData.petBirthday ?? "",
                petData.petBirthdateTypeId,
                window.hph_locale
              )}
            </div>
          </div>
          <div className="body3 lg:body2 flex w-[64px] flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              {genderIcon}
            </div>
            <div>{cardData.petGender || "-"}</div>
          </div>
          <div className="body3 lg:body2 flex w-[64px] flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              {weightIcon}
            </div>
            <div>{cardData.petSize || "-"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoCard;
